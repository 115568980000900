module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-scroll-reveal/gatsby-browser.js'),
      options: {"plugins":[],"threshold":0.3,"once":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"code by @nicofraisse","short_name":"code by @nicofraisse","description":"web development portfolio · creative freelance developer based in montreal · get in touch at info@nicolasfraisse.com","background_color":"#f7f3eb","theme_color":"#323442","lang":"en","icon":"src/images/logo.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"3639312344b12ac033d50b391f6d2176"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-177849708-1"},
    }]
